<template>
  <div class="financelist">
    <div class="title">
      <p class="c-box">
        <i class="el-icon-house" />
        <span @click="goto('/commodity')">首页</span> > <span class="">财务管理</span>
      </p>
    </div>
    <div class="search">
      <div class="c-box">
        <el-form :inline="true" :model="searchData" class="demo-form-inline">
          <el-row>
            <el-col :span="8">
              <el-form-item label="对账单编号：">
                <el-input v-model="searchData.billCode" placeholder="请输入" height="36px" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="账单号：">
                <el-input v-model="searchData.orderNo" placeholder="请输入" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="对账单状态">
                <el-select v-model="searchData.status" placeholder="请选择" clearable>
                  <el-option label="未付款" value="0" />
                  <el-option label="已付款" value="1" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="账单日期：" clearable>
                <el-date-picker
                  v-model="searchData.time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-button type="primary" round icon="el-icon-search" @click="findByPage">查询</el-button>
              <el-button type="success" round icon="el-icon-refresh-left" @click="resets">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="c-box">
      <div class="content">
        <el-table
          class="freightRate-table"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column prop="billCode" label="账单编号" align="center" />
          <el-table-column prop="createTime" label="账单日期" align="center" />
          <el-table-column prop="orderNo" label="账单号" align="center" />
          <el-table-column prop="receivableAmount" label="账单金额" align="center" />
          <el-table-column prop="receivedAmount" label="实收金额" align="center" />
          <el-table-column prop="status" label="账单状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status==0">未付款</span>
              <span v-if="scope.row.status==1">已付款</span>
            </template>
          </el-table-column>
          <el-table-column
            width="80px"
            label="操作"
            align="center"
          >
            <template slot-scope="scope">
              <i class="el-icon-edit-outline" style="cursor:pointer;font-size: 20px;margin-right:10px" @click="edit(scope.row)" />
              <a :href="href + '?ids=' + scope.row.id"><i class="el-icon-download" style="cursor:pointer;font-size: 20px" /></a>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="fenye"
          background
          layout="prev, pager, next"
          :current-page="searchData.pageNum"
          :page-size="searchData.pageSize"
          :total="total"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { findByPage } from '@/api/finance.js';
import { network, auto } from '@/config/geteway';
// import downFile from '@/api/down.js'
export default {
  name: 'Financelist',
  props: {

  },
  data: function() {
    return {
      searchData: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      tableData: [],
      href: `${network}/${auto}/receivablebillmaster/downloadBills`,
      form: {
        customerId: '',
        orderNo: '',
        destinationWarehouseCode: '',
        status: '',
        startShipment: '',
        destinationPort: '',
        sailTime: '',
        pageNum: 1,
        pageSize: 10
      }
    };
  },

  computed: {

  },
  watch: {

  },
  created() {
    this.findByPage();
  },
  mounted() {

  },
  methods: {
    // 搜索
    findByPage() {
      findByPage(this.searchData).then(result => {
        if (result.data.code == 0) {
          const data = result.data.data;
          this.tableData = data.list;
          this.total = data.total;
        } else {
          this.$message({ message: result.data.msg, type: 'warning' });
        }
      }).catch(() => {
        this.$message({ message: '请求失败', type: 'warning' });
      });
    },
    handleCurrentChange(val) { // 切换页数
      this.form.pageNum = val;
      this.getorderlisetinfo();
    },
    // 查看详情
    edit(row) {
      const id = row.id;
      this.$router.push({
        path: '/financedetails',
        query: {
          id
        }
      });
    },
    goto(route) {
      this.$router.push({ path: route });
    },
    // 重置
    resets() {
      this.searchData = {
        pageNum: 1,
        pageSize: 10
      };
      this.total = 0;
      this.findByPage();
    }
  }
};

</script>

<style scoped>
.title{height:30px;line-height:30px;background:#F0F0F0;margin:0;font-size:12px;color:#999999}
.title i{margin-right:10px;}
.title span{cursor:pointer;}
.title span:nth-child(3){color:#333}
.button{min-width: 80px;cursor:pointer;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 20px;}
.label{font-size:12px;display:inline-block;margin-right:20px}
.select{background:#FC6C3F;color:#fff;border-color:#FC6C3F !important;}
.transport{overflow:hidden;margin-top:44px}
.transport>div{width:50%;float:left;}
.search{margin:20px auto}
</style>
